@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.user-box-distributor {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.6rem;
  min-height: 2.4rem;
  background-image: -webkit-linear-gradient(290deg, #fff -500%, #e52779 70%);
  background-image: -webkit-linear-gradient(290deg, #fff -500%, var(--color-main, #e52779) 70%);
  background-image: linear-gradient(160deg, #fff -500%, #e52779 70%);
  background-image: linear-gradient(160deg, #fff -500%, var(--color-main, #e52779) 70%);
  color: #fff;
}
.user-box-distributor > .inner {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.user-box-distributor > .inner > .avatar {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background: transparent center no-repeat;
  background-size: cover;
  border: 0.04rem solid #fff;
}
.user-box-distributor > .inner > .avatar > .level {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: transparent center no-repeat;
  background-size: cover;
}
.user-box-distributor > .inner > .body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  margin-left: 0.2rem;
}
.user-box-distributor > .inner > .body > .info {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.user-box-distributor > .inner > .body > .info .nickname {
  font-weight: bold;
  font-size: 120%;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.user-box-distributor > .inner > .body > .recommend-code {
  display: inline-block;
  background-color: #fff;
  color: #e52779;
  color: var(--color-main, #e52779);
  padding: 0 0.1rem;
  border-radius: 0.1rem;
  margin-top: 0.1rem;
  font-size: 90%;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.expect-wrap[data-v-f33c6ea2] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
          align-items: baseline;
}
.expect-wrap div[data-v-f33c6ea2] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  text-align: center;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.goods-category {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  background-color: #e9ecef;
  overflow-y: auto;
  overflow-x: hidden;
}
.goods-category > .item {
  padding: 0.2rem;
  background-color: transparent;
  max-width: 6em;
}
.goods-category > .item.active {
  background-color: #fff;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.category[data-v-3da3a4b0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.category > .goods-wrap[data-v-3da3a4b0] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  overflow-y: auto;
  overflow-x: hidden;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.popup-wrap .shares-box.popup {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.popup-wrap .shares-box.popup > .inner {
  background-color: #fff;
  max-width: inherit;
  border-radius: 0;
  width: 100%;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.footer[data-v-5c67b602] {
  background-color: #fff;
}
.footer > .cart[data-v-5c67b602] {
  color: #868e96;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}
.share-earn[data-v-5c67b602] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2rem 0;
  background-color: #ffeff0;
  border: 0.05rem solid #f00;
  border-radius: 0.1rem;
}
.share-earn > .inner[data-v-5c67b602] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 0 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  border-right: 0.05rem dashed #f00;
  font-weight: bold;
}
.share-earn > .inner[data-v-5c67b602],
.share-earn > .inner .price[data-v-5c67b602] {
  color: #f00;
}
.share-earn > .inner .commission-list > .badge + .badge[data-v-5c67b602] {
  margin-top: 0.1rem;
}
.share-earn > .btn[data-v-5c67b602] {
  margin: 0 0.2rem;
}
.share-income[data-v-5c67b602] {
  padding: 0.2rem;
}
.share-income > .inner[data-v-5c67b602] {
  padding: 0.2rem;
  background-color: #ffeff0;
  border-radius: 0.1rem;
}
.share-income > .inner > .body[data-v-5c67b602] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 0.1rem;
}
.share-income > .inner > .body > .info > .label[data-v-5c67b602] {
  color: #f00;
}
.share-income > .inner > .body > .info > .value > .price[data-v-5c67b602] {
  color: #f00;
  font-size: 0.6rem;
  font-weight: bold;
  margin-right: 0.1rem;
}
.share-income > .inner > .body > .share-btn[data-v-5c67b602] {
  width: 1rem;
  height: 1rem;
  background-color: #f00;
  color: #fff;
  border-radius: 100vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1;
}
.share-income > .inner > .footer[data-v-5c67b602] {
  background-color: transparent;
  padding-top: 0.1rem;
  border-top: 1px dashed #f00;
}
.share-popup .canvas[data-v-5c67b602] {
  max-width: 80vw;
  height: auto;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.goods-swiper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.goods-swiper > .swiper-container {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.goods-swiper > .sold-out {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5) url(../../static/img/icon01.da5f90f1.png) center no-repeat;
  background-size: 70%;
  width: 100%;
  height: 100%;
}
.goods-info {
  background-color: #fff;
}
.goods-info > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.goods-info > .header > .price-wrap,
.goods-info > .header > .countdown-wrap {
  min-height: 1rem;
  padding: 0 0.2rem;
}
.goods-info > .header > .price-wrap {
  -webkit-box-flex: 0.54;
  -webkit-flex: 0.54 0.54 1%;
          flex: 0.54 0.54 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: #e52779;
  background-color: var(--color-main, #e52779);
}
.goods-info > .header > .price-wrap .price {
  color: #fff;
  line-height: 1.2;
}
.goods-info > .header > .price-wrap > .price {
  font-size: 0.4rem;
}
.goods-info > .header > .price-wrap > .old-price {
  color: #fff;
  font-size: 0.22rem;
  line-height: 1.2;
}
.goods-info > .header > .countdown-wrap {
  -webkit-box-flex: 0.46;
  -webkit-flex: 0.46 0.46 1%;
          flex: 0.46 0.46 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffcc33;
}
.goods-info > .header > .countdown-wrap > .countdown {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #f00;
}
.goods-info > .body {
  padding: 0.1rem 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.goods-info > .body > .label-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
.goods-info > .body > .label-wrap > .name {
  font-size: 0.32rem;
}
.goods-info > .body > .label-wrap > .desc {
  color: #868e96;
  line-height: 1.2;
  margin-top: 0.2rem;
}
.goods-info > .body > .share {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.22rem;
  color: #868e96;
}
.goods-info > .body > .share > .f-icon {
  font-size: 0.6rem;
  line-height: 1;
  margin-bottom: 0.1rem;
}
.goods-info > .body > .share > .label {
  white-space: nowrap;
}
.goods-info > .body > .share.active {
  color: #e52779;
  color: var(--color-main, #e52779);
}
.goods-info > .footer {
  padding: 0.1rem 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.cube-toolbar > .item[data-v-7ac1871d] {
  width: 50%;
}
.cube-toolbar > .item > .f-icon[data-v-7ac1871d] {
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
}
.recommend > .copywriting > .header[data-v-7ac1871d] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.1rem 0;
  border-bottom: 1px solid #f1f1f1;
}
.recommend > .copywriting > .body[data-v-7ac1871d] {
  padding: 0.1rem 0;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.share-graphic {
  padding: 0 0.2rem;
}
.share-graphic > .inner {
  background-color: #fff;
  padding-top: 0.2rem;
  border-radius: 0.05rem;
}
.share-graphic > .inner > .swiper-container {
  width: 100% !important;
}
.share-graphic > .inner > .swiper-container .swiper-slide > .inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-color: #fff;
}
.share-graphic > .inner > .swiper-container .swiper-slide > .inner > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.share-graphic > .inner > .swiper-container .swiper-slide > .inner > .loading {
  width: 0.5rem;
  height: 0.5rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.list[data-v-ddb4dd1a] {
  padding: 0;
}
.list > .item[data-v-ddb4dd1a] {
  padding: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  border-bottom: 1px solid #f1f1f1;
}
.list > .item + .item[data-v-ddb4dd1a] {
  margin-top: 0.3rem;
}
.list > .item > .label[data-v-ddb4dd1a] {
  padding: 0 0.2rem;
  font-size: 0.3rem;
  line-height: 0.8rem;
  font-weight: bold;
  background-color: #e9ecef;
}
.list > .item > .details[data-v-ddb4dd1a] {
  padding: 0.2rem;
  font-size: 0.26rem;
  line-height: 1.1;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.second-header > .time-select {
  background-color: #fff;
  line-height: 0.9rem;
}
.classify-list {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  margin-bottom: 0.3rem;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  height: 100%;
}
.classify-list .right-icon {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  top: 0;
  right: 0;
  width: 0.8rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}
.classify-list .item {
  /*width: 20%;*/
  height: 100%;
  padding: 0.2rem 1em;
  border-top: 0.667vw solid transparent;
  border-bottom: 0.667vw solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.classify-list .item > .label {
  line-height: 1;
}
.classify-list .item.active {
  color: #e52779;
  color: var(--color-main, #e52779);
  border-bottom: 0.667vw solid #e52779;
  border-bottom: 0.667vw solid var(--color-main, #e52779);
}
.second-header.classify {
  overflow: hidden;
}
.second-header.classify .classify-list {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  white-space: inherit;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  height: calc(100% + 0.4rem);
}
.second-header.classify .classify-list > .item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 0.8rem;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  text-align: center;
}
.second-header.classify {
  height: 0.8rem;
}
.order-list > .item {
  background-color: #fff;
  margin-bottom: 0.3rem;
}
.order-list > .item > .header {
  padding: 0.2rem;
  border-bottom: 1px solid #f1f1f1;
}
.order-list > .item > .header > .label {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.order-list > .item > .header > .order-sn {
  color: #868e96;
}
.order-list > .item > .footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2rem;
  border-top: 1px solid #f1f1f1;
}
.order-list > .item > .footer > .btn + .btn {
  margin-left: 0.2rem;
}
.goods-cart-list {
  display: block;
  background-color: #fff;
}
.goods-cart-list > .item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2rem;
}
.goods-cart-list > .item:first-child {
  border-top: 0;
}
.goods-cart-list > .item > .checkbox {
  margin-right: 0.2rem;
}
.goods-cart-list > .item .img-obj {
  position: relative;
  min-width: 1.88rem;
  width: 1.88rem;
  height: 1.88rem;
  background-size: cover;
  background-position: center;
}
.goods-cart-list > .item .img-obj > .inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 0.3rem;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.goods-cart-list > .item .img-obj > .inner.sell-out {
  background: rgba(0, 0, 0, 0.5) url(../../static/img/icon01.da5f90f1.png) center no-repeat;
  background-size: auto 70%;
}
.goods-cart-list > .item .img-obj > .inner.sold-out {
  background: rgba(0, 0, 0, 0.5) url(../../static/img/icon02.879e3bae.png) center no-repeat;
  background-size: auto 70%;
}
.goods-cart-list > .item .inner {
  min-width: inherit;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  height: 1.88rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 0 0 0 0.2rem;
}
.goods-cart-list > .item .inner > dl {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.goods-cart-list > .item .inner > dl > .label {
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 0.2rem;
  font-size: 0.26rem;
  line-height: 1.5;
  min-height: 0.78rem;
}
.goods-cart-list > .item .inner > dl > .del {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.goods-cart-list > .item .inner > dl > .del > .btn {
  white-space: nowrap;
}
.goods-cart-list > .item .inner > dl > dd {
  white-space: nowrap;
  text-align: right;
}
.goods-cart-list > .item .inner > .sku {
  border: 1px solid #e52779;
  border: 1px solid var(--color-main, #e52779);
  padding: 0 0.1rem;
  border-radius: 0.1rem;
  color: #e52779;
  color: var(--color-main, #e52779);
}
.goods-cart-list > .item > .body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  padding: 0.2rem;
  box-shadow: 0 0.4vw 1.333vw 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.05rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.user-box-ranking {
  position: relative;
  width: 100%;
  min-height: 32vw;
  /*background: url("~@/assets/images/img/user-bg.jpg") center no-repeat;*/
  background-color: #fff;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #f1f1f1;
}
.user-box-ranking > .info {
  /*width: 80%;*/
  /*color: #fff;*/
  padding: 0.2rem;
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: flex-start;*/
  /*align-items: center;*/
  text-align: center;
}
.user-box-ranking > .info > .img-obj {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  width: 21vw;
  height: 21vw;
  background-size: cover;
  background-position: center center;
  background-color: #f8f9fa;
  /*box-shadow: 0 0 20px 10px rgba(255, 255, 255, .5);*/
  border-radius: 50%;
  /*border: 8px solid #1d1b00;*/
}
.user-box-ranking > .info > .img-obj > .f-icon {
  font-size: 12vw;
  color: #adb5bd;
}
.user-box-ranking > .info > .img-obj > .icon3 {
  position: absolute;
  bottom: 0.05rem;
  right: 0.05rem;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  overflow: hidden;
  background: transparent url(../../static/img/icon03.51f609a2.png) center no-repeat;
  background-size: 100% 100%;
}
.user-box-ranking > .info > .user-name {
  font-size: 0.3rem;
}
.user-box-ranking .level .level-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.user-box-wrap {
  margin-bottom: 0.3rem;
}
.user-box-wrap > .user-box {
  padding: 0.2rem;
  border: 0;
}
.user-box-wrap > .user-box .price {
  color: #f00;
  font-size: 0.5rem;
}
.user-box-wrap > .user-box .price:before {
  font-size: 0.4rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.ranking-label {
  padding: 0.2rem;
  text-align: center;
  color: #e52779;
  color: var(--color-main, #e52779);
  font-size: 0.4rem;
  font-weight: bold;
  background-color: #fff;
}
.ranking-table {
  background-color: #fff;
}
.ranking-table > table {
  width: 100%;
  border-collapse: collapse;
}
.ranking-table > table tr {
  border-bottom: 1px solid #f1f1f1;
}
.ranking-table > table tr th,
.ranking-table > table tr td {
  text-align: center;
  padding: 0.2rem 0.1rem;
}
.ranking-table > table > thead th {
  font-weight: normal;
  white-space: nowrap;
}
.ranking-table > table > tbody > tr:nth-child(1) .medal, .ranking-table > table > tbody > tr:nth-child(2) .medal, .ranking-table > table > tbody > tr:nth-child(3) .medal {
  font-weight: bold;
  color: #f00;
  padding: 0.1rem;
  background: transparent url(../../static/img/medal2.83540bd2.png) center bottom no-repeat;
  background-size: contain;
}
.ranking-table > table > tbody > tr .medal {
  margin: 0 auto;
  width: 0.5rem;
  height: 0.6rem;
  line-height: 0.6rem;
}
.ranking-table > table > tbody > tr .avatar {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.verify {
  text-align: center;
  padding: 0.2rem;
}
.verify > .f-icon {
  font-size: 1.4rem;
  line-height: 1;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.container[data-v-5933162a] {
  padding: 0.3rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.container .upload[data-v-5933162a] {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.salesman-item {
  display: block;
  padding: 0.2rem;
  background-color: #fff;
  border-radius: 0.1rem;
  box-shadow: 0 0.04rem 0.12rem -0.06rem #868e96;
}
.salesman-item + .salesman-item {
  margin-top: 0.2rem;
}
.salesman-item > .inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.salesman-item > .inner > .header {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.salesman-item > .inner > .header > .avatar {
  position: relative;
  background: center no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  border-radius: 100vw;
  margin-right: 0.1rem;
}
.salesman-item > .inner > .header > .avatar > .rank {
  position: absolute;
  top: -0.1rem;
  left: -0.1rem;
  display: inline-block;
}
.salesman-item > .inner > .header > .avatar > .rank > .f-icon {
  font-size: 0.42rem;
}
.salesman-item > .inner > .header > .avatar > .rank > .inner {
  background-color: #ffcc33;
  display: inline-block;
  height: 1.2em;
  min-width: 1.2em;
  line-height: 1.2;
  text-align: center;
  border-radius: 100vw;
  color: #fff;
}
.salesman-item > .inner > .header > .avatar > .rank.rank1, .salesman-item > .inner > .header > .avatar > .rank.rank2, .salesman-item > .inner > .header > .avatar > .rank.rank3 {
  position: relative;
}
.salesman-item > .inner > .header > .avatar > .rank.rank1 .inner, .salesman-item > .inner > .header > .avatar > .rank.rank2 .inner, .salesman-item > .inner > .header > .avatar > .rank.rank3 .inner {
  font-size: 90%;
  position: absolute;
  right: 0;
  bottom: 0.267vw;
  left: 0;
  background-color: transparent;
}
.salesman-item > .inner > .header > .avatar > .rank.rank1 > .f-icon {
  color: #ffcc33;
}
.salesman-item > .inner > .header > .avatar > .rank.rank2 > .f-icon {
  color: #ff8f0e;
}
.salesman-item > .inner > .header > .avatar > .rank.rank3 > .f-icon {
  color: #cc8211;
}
.salesman-item > .inner > .header > .inner > .name {
  font-size: 110%;
  font-weight: bold;
}
.salesman-item > .inner > .header > .inner > .mobile {
  color: #999;
  font-size: 90%;
}
.salesman-item > .inner > .body {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}
.salesman-item > .inner > .body > .item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.1rem 0;
}
.salesman-item > .inner > .body > .item + .item {
  border-top: 1px solid #f1f1f1;
}
.salesman-item > .inner > .body > .item > .label {
  display: none;
}
.salesman-item > .inner > .body > .item > .extra {
  white-space: nowrap;
  text-align: right;
  /*display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: baseline;*/
}
.salesman-item > .inner > .body > .item > .extra > .order {
  font-size: 80%;
  color: #999;
}
.salesman-item > .inner > .body > .item > .help {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  color: #f00;
  font-size: 120%;
  margin-left: 0.1rem;
}
.salesman-item > .footer {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-top: 0.2rem;
}
.salesman-item > .footer > .item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 0.1rem;
  border-top: 1px solid #f1f1f1;
}
.salesman-item > .footer > .item > .label {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
}
.salesman-item > .footer > .item > .extra {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  white-space: nowrap;
  text-align: right;
}
.salesman-item > .footer > .item > .extra > .order {
  font-size: 80%;
  color: #999;
}
.salesman-item > .footer > .item > .help {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  color: #f00;
  font-size: 120%;
  margin-left: 0.1rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.second-header[data-v-01eb7bcd] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.second-header > .period[data-v-01eb7bcd] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.1rem;
  background-color: #fff;
}
.second-header > .period > .inner[data-v-01eb7bcd] {
  font-size: 80%;
  padding: 0.1rem;
  border-radius: 0.1rem;
  border: 1px solid #f1f1f1;
}
.second-header > .period.active > .inner[data-v-01eb7bcd] {
  background-color: #e52779;
  background-color: var(--color-main, #e52779);
  border-color: #e52779;
  border-color: var(--color-main, #e52779);
  color: #fff;
}
.second-header > .time-select[data-v-01eb7bcd] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.container[data-v-29858410] {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  color: #868e96;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.coupon-state-item {
  padding: 0.1rem 0.2rem;
  background-color: #fff;
}
.coupon-state-item + .coupon-state-item {
  margin-top: 0.2rem;
}
.coupon-state-item > .label {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.coupon-state-item > .label > .name {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  font-weight: bold;
}
.coupon-state-item > .label > .time {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  color: #999;
}
.coupon-state-item > .desc {
  color: #999;
}
.coupon-state-item > .content > .item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.1rem 0;
}
.coupon-state-item > .content > .item + .item {
  border-top: 1px solid #f1f1f1;
}
.coupon-state-item > .content > .item > .value {
  color: #e52779;
  color: var(--color-main, #e52779);
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.container[data-v-c77d4e5a] {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  color: #868e96;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.distributor-detail {
  margin-bottom: 0.2rem;
}
.distributor-detail > .img-obj {
  width: 100%;
}
.distributor-detail > .img-obj > .inner {
  width: 100%;
  height: 0;
  padding-top: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.distributor-detail > .header {
  padding: 0.2rem;
  background-color: #fff;
}
.distributor-detail > .footer {
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.second-header[data-v-060518a8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 0.2rem;
  background-color: #fff;
}
.second-header > .select[data-v-060518a8] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  line-height: 0.8rem;
  border: 0;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.second-header > .time-select[data-v-599ee7e0] {
  background-color: #fff;
  line-height: 0.9rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.team-log-item {
  padding: 0.2rem;
  background-color: #fff;
}
.team-log-item + .team-log-item {
  margin-top: 0.2rem;
}
.team-log-item > .time {
  font-weight: bold;
}
.team-log-item > .desc {
  color: #868e96;
  word-break: break-all;
  white-space: pre-line;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.percentage-item {
  background-color: #fff;
}
.percentage-item + .percentage-item {
  margin-top: 0.2rem;
}
.percentage-item > .header {
  padding: 0.2rem;
  border-bottom: 1px solid #f1f1f1;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.money-box[data-v-5246da6a] {
  background-image: -webkit-linear-gradient(290deg, #fff -500%, #e52779 70%);
  background-image: -webkit-linear-gradient(290deg, #fff -500%, var(--color-main, #e52779) 70%);
  background-image: linear-gradient(160deg, #fff -500%, #e52779 70%);
  background-image: linear-gradient(160deg, #fff -500%, var(--color-main, #e52779) 70%);
}
.money-box > .money[data-v-5246da6a],
.money-box > .label[data-v-5246da6a] {
  color: #fff;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.bestow-item {
  background-color: #fff;
}
.bestow-item + .bestow-item {
  margin-top: 0.2rem;
}
.bestow-item .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2rem;
  border-bottom: 1px solid #f1f1f1;
}
.bestow-item .header > .code {
  color: #868e96;
}
.bestow-item > .body {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2rem;
}
.bestow-item > .body > .user {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.bestow-item > .body > .user > .avatar {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #e9ecef center no-repeat;
  background-size: cover;
}
.bestow-item > .body > .user > .info {
  margin-left: 0.2rem;
}
.bestow-item > .body > .user > .info > .nickname {
  font-weight: bold;
}
.bestow-item > .body > .user > .info > .mobile {
  color: #49a9ee;
}
.bestow-item > .body .used-time {
  color: #868e96;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}

/*# sourceMappingURL=distributor.dda24536.css.map*/